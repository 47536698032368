// import our other sub sheets
@import 'config';
@import 'reset';

h1, h2, h3{
  color: $supercat-primary;
}

.question{
  color: $supercat-primary;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

/* Desktop formatting rules */
@media screen and (min-width: 700px) {

/*********************/
/* Tag Redefs
/*********************/
body{
	margin: 0px;
	font-family: Geneva, Helvetica, Arial, san-serif;
	font-size: 14px;
	padding: 0;
	text-align: center; /* this centers the container in IE 5* browsers. The text is then set to the left aligned default in the #container selector */
	color: $supercat-darktext;
	line-height: 20px;
	height: 100%;
}

h1{
	font-size: 18px;
	margin: 0px 0px 10px 0px;
}

h2{
	font-size: 14px;
	margin-top: 30px;
	text-transform: uppercase;
}

#content ul li{
	list-style-type: circle;
	list-style-position: outside;
	margin-left: 15px;
}

p{
	margin: 0px 0px 15px 0px;
}

p.noMargin{
	margin: 0px 0px 5px 0px;
}

.noMargin{
	margin: 0px;
}

a{
	color: $supercat-secondary;
	text-decoration: none;
}

a:hover{
	text-decoration: underline;
}

a:visited{

}

a:visited:hover{

}

img{
	border: none;
}

/*********************/
/* All Pages
/*********************/
#logo{

}

#content #interiorContent .link{
	margin: 0px 0px 2px 0px;
	padding: 0px;
}

#interiorContent ol li{
	margin: 0px 0px 12px 25px;
}

.highlight{
	font-weight: bold;
	color: $supercat-highlight;
}

/*********************/
/* Home Page
/*********************/
.homeImage{
	margin: 50px 0px 0px 0px;
	text-align: center;
}


/*********************/
/* Interior Pages
/*********************/


.answer{
	color: $supercat-mediumtext;
	padding: 0px;
	margin: 0px 0px 30px 0px;
}

/*********************/
/* All Pages
/*********************/
main{
	min-height: 100%;
	width: 700px;
	margin: 0 auto;
	padding: 10px 0px 0px 0px;
	height: 100%;
	text-align: left;
}

#content{
	margin: 20px 0px 0px 0px;
}

footer{
	text-align: center;
	color: $supercat-lighttext;
	font-size: 10px;
	padding: 60px 0px 20px 0px;
	clear: both;
}


/*********************/
/* Navigation
/*********************/
nav{
	margin: 8px 0px 8px 0px;
}

nav ul li{
	list-style-type: none;
	display: inline;
	margin-right: 40px;
	margin-left: 10px;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: bold;
}

nav ul li.rightNav{
	margin-right: 0px;
}

nav ul li a, nav ul li a:visited{
	text-decoration: none;
	color: $supercat-lighttext;
}

nav ul li a:hover, nav ul li a:visited:hover, nav ul li a.active:hover, nav ul li a.active:visited:hover{
	color: $supercat-mediumtext;
}

nav ul li a.active, nav ul li a.active:visited{
	text-decoration: none;
	color: $supercat-blacktext;
}

/*********************/
/* Interior Pages
/*********************/
#interiorContent{
	width: 450px;
	margin: 0 auto;
	text-align: left;
	float: right;
}

#sidebar{
	float: left;
	width: 185px;
	padding-right: 15px;
}

}

/* Mobile formatting rules */
@media only screen and (max-width: 700px) {

	/*********************/
	/* Tag Redefs
	/*********************/
	body{
		font-family: Geneva,Helvetica,Arial,san-serif;
		font-size: 14px;
		padding: 0;
		text-align: center; /* this centers the container in IE 5* browsers. The text is then set to the left aligned default in the #container selector */
		color: $supercat-darktext;
		line-height: 20px;
		height: 100%;
	}

	h1, h2, h3{
		color: $supercat-darktext;
	}

	h1{
		font-size: 18px;
		margin: 0px 0px 15px 0px;
	}

	h2{
		font-size: 14px;
		margin-top: 20px;
	}

	#content ul li{
		list-style-type: circle;
		list-style-position: outside;
		margin-left: 15px;
	}

	p{
		margin: 0px 0px 15px 0px;
	}

	p.noMargin{
		margin: 0px 0px 5px 0px;
	}

	.noMargin{
		margin: 0px;
	}

	a{
		color: $supercat-secondary;
		text-decoration: none;
	}

	a:hover{
		text-decoration: underline;
	}

	a:visited{

	}

	a:visited:hover{

	}

	img{
		border: none;
	}

	/*********************/
	/* Navigation
	/*********************/
	nav{
		margin: 8px 0px 8px 0px;
		width: 100%;
		text-align: center;
	}

	nav ul li.rightNav{
		margin-right: 0px;
	}

	nav ul li{
		list-style-type: none;
		margin: 2px 0px 0px 0px;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: bold;
	}

	nav ul li a:link, nav ul li a:visited{
		display: block;
		margin: 0px;
		height: 28px;
		padding-top: 8px;
		width: 100%;
		text-decoration: none;
		color: $supercat-white;
		background: none repeat scroll 0 0 $supercat-lighttext;
		vertical-align: middle;
	}

	nav ul li a:hover, nav ul li a:visited:hover, nav ul li.active a:hover, nav ul li.active a:visited:hover{
		background-color: $supercat-darktext;
		color: $supercat-white;
		vertical-align: middle;
	}

	nav ul li a.active:link, nav ul li a.active:visited{
		display: block;
		margin: 0px;
		height: 28px;
		padding-top: 8px;
		width: 100%;
		text-decoration: none;
		background-color: $supercat-secondary;
		color: $supercat-white;
		vertical-align: middle;
	}

	/*********************/
	/* All Pages
	/*********************/
	main{
		display: block;
		width: 100%;
		margin: 0 auto;
		text-align: left;
	}

	#content{
		display: block;
		margin: 20px;
	}

	#logo{

	}

	footer{
		text-align: center;
		color: $supercat-lighttext;
		font-size: 10px;
		padding: 10px 0px 10px 0px;
		clear: both;
	}

	/*********************/
	/* Interior Pages
	/*********************/
	#interiorContent{
		margin: 0 auto;
		text-align: left;
		display: block;
		width: 97%;
	}

	#sidebar{
		margin-top: 25px;
		border: thin;
		border-color: $supercat-lighttext;
		border-style: solid;
		padding: 20px;
	}

}
