/*********************/
/* CSS Reset
/*********************/
html, body, div, p, span, object, applet, iframe, h1, h2, h3, h4, h5, h6, blockquote, pre, a, abr, acronymn,
address, big, cite, code, del, em, img, strike, strong, var, ol, li, ul, fieldset, label, legend, table, caption, tr, th, td{
	margin: 0px;
	padding: 0px;
	border: 0;
	outline: 0;
}
