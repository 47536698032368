//_config.css

/* colors */
$supercat-primary : #094A9C;
$supercat-secondary : #007BBC;
$supercat-darktext : #333;
$supercat-blacktext : #000;
$supercat-mediumtext : #666;
$supercat-lighttext : #999;
$supercat-white : #FFF;
$supercat-highlight : #3F3F3F;
